import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { encodeQueryParams } from "utils/axios.utils";

export const BaseURL = {
  MainService: process.env.REACT_APP_API_URL,
  AIChatService: process.env.REACT_APP_CHATBOT_URL,
};

const DEFAULT_OPTIONS = { isAuthorized: true, baseURL: BaseURL.MainService };

export const postRequest = async ({
  endpoint,
  data,
  options = {},
  headers = {},
}) => {
  try {
    options = { ...DEFAULT_OPTIONS, ...options };

    const config = {};
    let _headers = { ...headers };
    if (options.isAuthorized) {
      const authToken = localStorage.getItem("authToken");
      _headers = { ..._headers, Authorization: `Bearer ${authToken}` };
    }

    config.headers = _headers;
    return axios
      .post(options.baseURL + endpoint, data, config)
      .catch((error) => {
        if (error.response.status === 500) {
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
  } catch (error) {
    throw error;
  }
};

export const getRequest = async ({
  endpoint,
  params = {},
  options = {},
  headers = {},
}) => {
  try {
    options = { ...DEFAULT_OPTIONS, ...options };

    const config = {};
    let _headers = { ...headers };
    if (options.isAuthorized) {
      const authToken = localStorage.getItem("authToken");
      _headers = { ..._headers, Authorization: `Bearer ${authToken}` };
    }

    config.headers = _headers;

    return axios
      .get(options.baseURL + endpoint + encodeQueryParams(params), config)
      .catch((error) => {
        if (error.response.status === 500) {
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
  } catch (error) {
    throw error;
  }
};
